<template>
  <div>
    <ed-documento :intCadastroGeralEmpresaId="0" :intTipoDocumentoId="279" :intTipoRevisaoId="285" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdDocumento from "@/components/documento/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdDocumento },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>